
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200&family=Nunito:wght@200&display=swap');

body{
  font-family: 'DM Sans', sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200&family=Inconsolata:wght@200&family=Poppins&display=swap");
.App {
  text-align: center;
  width: "100%";
  height: 100vh;
  color: white;
  transition-duration: 0.6s;
  font-family: "Inconsolata", monospace;
  font-family: "Poppins", sans-serif;

  background: rgb(2, 9, 21);
  background: "linear-gradient(90deg,rgba(2, 0, 36, 1) 0%,rgba(26, 30, 29, 1) 35%,rgba(49, 59, 61, 1) 100%)";
}
.image:hover {
  cursor: pointer;
  scale: 1.2;
  opacity: 0.3;
  transition-duration: 1s;
}
.contactheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 10px;
}

#close:hover {
  cursor: pointer;
}
.inlinesocialcard {
  display: flex;
  flex-direction: row;
  width: 400;
  flex-wrap: wrap;
}
.jointelegram {
  align-self: center;
  background-color: #006885;
  width: 300px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.jointelegram:hover {
  cursor: pointer;
  background-color: #004355;
}
.socialcard {
  background-color: #282c34;
  width: 100px;
  height: 100px;
  margin-inline: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialcard:hover {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(11, 12, 12) 35%,
    rgb(22, 29, 31) 100%
  );
  cursor: pointer;
}
.circle {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-self: center;
}
.circle .dots {
  height: 10px;
  width: 10px;
  border: 1px solid blanchedalmond;
  border-radius: 100%;
  margin-inline: 5px;
}
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  position: fixed;
  background: rgb(2, 9, 21);
  background: "linear-gradient(90deg,rgba(2, 0, 36, 1) 0%,rgba(26, 30, 29, 1) 35%,rgba(49, 59, 61, 1) 100%)";

  z-index: 11;
  backdrop-filter: blur(10px);
  width: 100%;
}
ul {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
}
.homeTag {
  display: flex;
  padding-top: 100px;

  flex-direction: column;
  align-items: flex-start;
  margin-left: 200px;
  height: 500px;

  justify-content: center;
}

.services {
  font-weight: bold;

  color: black;

  height: 700px;
  background: rgb(2, 9, 21);
  background: "linear-gradient(90deg,rgba(2, 0, 36, 1) 0%,rgba(26, 30, 29, 1) 35%,rgba(49, 59, 61, 1) 100%)";

  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
}
.services h1 {
  color: rgb(255, 255, 255);
}
.projects {
  background: rgb(2, 9, 21);
  background: "linear-gradient(90deg,rgba(2, 0, 36, 1) 0%,rgba(26, 30, 29, 1) 35%,rgba(49, 59, 61, 1) 100%)";
}

h1 {
  margin: 0px;
}
h3 {
  margin: 0px;
}
li {
  list-style: none;
  width: 90px;
  height: 40px;

  margin-left: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline {
  display: flex;
  flex-direction: row;

  width: 600px;

  flex-wrap: wrap;
}

.container {
  height: 250px;
  width: 250px;
  position: relative;
  perspective: 500px;
  margin: 20px;
}
.foldcard {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.container:hover > .foldcard {
  transform: rotateY(180deg);
}
.front {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0.171) 0%,
    rgba(9, 9, 121, 0.151) 35%,
    rgba(0, 213, 255, 0.13) 100%
  );
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  backface-visibility: hidden;
}
.back p {
  margin: 0px;
  display: flex;
  text-align: center;
  align-items: center;
}
.back {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.commentcard {
  border: 1px solid rgb(32, 32, 31);
  height: 300px;
  background: rgb(2, 9, 21);
  background: "linear-gradient(90deg,rgba(2, 0, 36, 1) 0%,rgba(26, 30, 29, 1) 35%,rgba(49, 59, 61, 1) 100%)";
  box-shadow: rgba(218, 212, 212, 0.35) 0px 5px 15px;
  border-radius: 20px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.commentcard p {
  width: 80%;
  height: 100px;

  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
#activate {
  color: #61dafb;
  cursor: pointer;
}
#contact {
  background-color: #61dafb;
  margin-inline: 20px;
}
#contact:hover {
  color: black;
}
.aboutas {
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.aboutas img {
  border-radius: 60px;
  border: 4px solid white;
  opacity: 0.2;
  margin-right: 10px;
}
.aboutas p {
  width: 500px;
  text-align: justify;
}

.comments {
  height: 550px;
  display: flex;
  flex-direction: column;
  width: 920px;
  justify-content: center;

  align-self: center;

  flex-wrap: nowrap;
  margin-left: 14%;

  overflow: hidden;
}
.ccards {
  margin-inline: 30px;
}
.commentlist {
  display: flex;
  flex-direction: row;
  align-items: center;
}
li:hover {
  color: #61dafb;
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
  justify-content: space-evenly;
}
.footer .text {
  display: flex;
  flex-direction: row;
}
#icon {
  margin-inline: 10px;
}
